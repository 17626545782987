import React, { useState } from "react"
import { v4 } from "uuid"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Hero from "../components/Hero"
import CategoryProductSection from "../sections/Product/CategoryProductSection"
import ProductRoofStyle from "../sections/Product/ProductRoofStyle"
import CallNowSection from "../components/CallNow"
import BuildingStyleCard from "../components/BuildingStyleCard"
import {
  Section,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import CustomQuote from "../components/CustomQuote"
import QuotePopup from "../components/QuotePopup"
import { GatsbyImage } from "gatsby-plugin-image"

const _ = require('lodash');

const SubCategoryList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
`

const SubCategoryCard = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  border-radius: 10px;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  @media(min-width: 600px) {
    width: calc((100% - 60px)/2);
  }

  @media(min-width: 1024px) {
    width: calc((100% - 60px)/3);
  }

  .name {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background: rgb(11, 97, 155);
    color: white;
    font-size: 20px;
    line-height: 1.5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0;
    padding: 10px 0;
  }
`

const CategoryPage = ({ data, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const pageData = data.contentfulMainCategoryLanding
  const allRoofStyles = data.allContentfulRoofStyle.edges
  const productsData = data.allContentfulProduct.edges

  let allSubCategories = []
  let allProductApplications = []

  data.allContentfulProduct.edges.forEach(function(item) {
    if (item.node.productCategory.name === pageData.category.name) {
      item.node.productSubCategories.forEach(function(node) {
        allSubCategories.push(node.name)  
      })
      item.node.productApplications.forEach(function(node) {
        allProductApplications.push(node.name)
      })
    }
  })

  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
      <BreadCrumb className="white">
        <div className="container">
          <Link to="/">Home </Link>
          <span>/ {pageData.category.name}</span>
        </div>
      </BreadCrumb>
      <Hero
        title={pageData.heroTitle}
        description={pageData.heroDescription}
        image={pageData.heroImage}
        openQuoteModal={() => setQuoteVisible(true)}
      />      
      <CategoryProductSection
        sectionShadow={pageData.productListLabel}
        sectionTitle={pageData.productListTitle}
        data={productsData}
        category={pageData.category.name}
        buildingType={_.uniq(allSubCategories)}
        buildingApplication={_.uniq(allProductApplications)}
        allRoofStyles={allRoofStyles}
        openQuoteModal={() => setQuoteVisible(true)}
      />
      <Section pt="45px" pb="45px" xpt="30px" xpb="30px" bgColor="#fff">
        <div className="container">
          <SectionTitle textAlign="left" maxWidth="910px" ml="0">
            {pageData.roofStylesSectionTitle}
          </SectionTitle>
          <SectionDescription
            maxWidth="910px"
            ml="0"
            mb="30px"
            textAlign="left"
          >
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.roofStylesSectionDescription.childMarkdownRemark
                    .html,
              }}
            />
          </SectionDescription>
        </div>
        <ProductRoofStyle data={pageData.roofStyles} />
      </Section>
      <CallNowSection />
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F9F9F9">
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <MarkdownContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.pageContent.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                <CustomQuote location={location}/>
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      {pageData.category.name === "Metal Buildings" && (
        <Section pt="90px" pb="90px" xpt="30px" xpb="30px" bgColor="#fff">
          <div className="container">
          <SectionTitle textAlign="center" mb="40px">Our {pageData.category.name} Styles</SectionTitle>
            <SubCategoryList>
              {pageData.subCategoriesLanding.map((item, i) => (
                <SubCategoryCard key={item.subCategory.name} to={item.subCategory.url}>
                  <GatsbyImage image={item.heroImage.gatsbyImageData} alt={item.subCategory.name} />
                  <h3 className="name" >{item.subCategory.name}</h3>
                </SubCategoryCard>
              ))}
            </SubCategoryList>
          </div>
        </Section>
      )}
      {pageData.category.name !== "Metal Buildings" && pageData.buildingStyles && (
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
          <div className="container">
            <SectionTitle textAlign="center" mb="40px">Our {pageData.category.name} Styles</SectionTitle>
            {pageData.buildingStyles.map(item => item.image?.gatsbyImageData && (
              <BuildingStyleCard key={v4()} data={item} />
            ))}
          </div>
        </Section>
      )}
      <QuotePopup
        states={data.allContentfulState.edges}
        location={location}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default CategoryPage

export const pageQuery = graphql`
  query ProductMainCategoryLandingQuery($id: String!) {
    contentfulMainCategoryLanding(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        title
        gatsbyImageData
      }
      category {
        name
        url
      }
      productListLabel
      productListTitle
      roofStylesSectionTitle
      roofStylesSectionDescription {
        childMarkdownRemark {
          html
        }
      }
      roofStyles {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData(height: 458)
        }
        buttonText
        buttonLink
      }
      pageContent {
        childMarkdownRemark {
          html
        }
      }
      buildingStyles {
        label
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          gatsbyImageData
        }
        buttonText
        buttonLink
      }
      subCategoriesLanding {
        subCategory {
          name
          url
        }
        heroImage {
          gatsbyImageData(quality: 100, width: 500)
        }
      }
    }
    allContentfulSubCategoryLanding(
      filter: {category: {name: {eq: "Metal Buildings"}}}
    ) {
      edges {
        node {
          subCategory {
            name
            url
          }
          heroImage {
            gatsbyImageData(quality: 100, width: 500)
          }
        }
      }
    }
    allContentfulProduct {
      edges {
        node {
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulRoofStyle {
      edges {
        node {
          name
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
